<template>
  <div id="newsStoryContent">
    <!-- pic70C56EA1AEE84B0E8D65BE66C2BB09DC.jpg -->
    <img id="Image1" class="rounded img-large" src="">
    <p id="Text1">
      LEGO Universe® is headed to sunny San Diego for Comic-Con International 2010, the largest
      comic book convention in the world! From July 22 through July 25, we will share more sneak-peeks
      of the creativity-fueled online game. So stop by if you’re at the show!
      <br><br>
      There will be lots of cool activities! Fans will be able to demo the game on the show floor,
      get the chance to take home one of the limited edition LEGO Universe posters signed (on the
      spot) by concept artist Mike Rayhawk (the founder of the famous BrikWars). Or maybe even
      get their hands on one of the limited Beta keys being given away.
      <br><br>
      Most excitingly, some of the impressive creatures submitted to the
      <router-link href="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab">'Build a Pet' challenge</router-link>
      on legouniverse.com have been selected to be featured in a game demostration. On Sunday, July 25,
      members of the LEGO Universe team will take to the stage to give a special Comic-Con
      presentation, and bring them to life in front of a live audience!
      <br><br>
      Stay tuned to see if your pet was chosen and to watch some cool event-related videos!
    </p>
  </div>
</template>
